/*
Copyright 2015 - 2021 The Matrix.org Foundation C.I.C.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

.mx_MFileBody_download {
    color: $accent;

    .mx_MFileBody_download_icon {
        /* 12px instead of 14px to better match surrounding font size */
        width: 12px;
        height: 12px;
        mask-size: 12px;

        mask-position: center;
        mask-repeat: no-repeat;
        mask-image: url("$(res)/img/download.svg");
        background-color: $accent;
        display: inline-block;
    }
}

.mx_MFileBody_download a {
    color: $accent;
    text-decoration: none;
    cursor: pointer;
}

.mx_MFileBody_download object {
    margin-left: -16px;
    padding-right: 4px;
    margin-top: -4px;
    vertical-align: middle;
    pointer-events: none;
}

/* Remove the border and padding for iframes for download links. */
.mx_MFileBody_download iframe {
    margin: 0px;
    padding: 0px;
    border: none;
    width: 100%;
    /* Set the height of the iframe to be 1 line of text.
     * Iframes don't automatically size themselves to fit their content.
     * So either we have to fix the height of the iframe using CSS or
     * use javascript's cross-origin postMessage API to communicate how
     * big the content of the iframe is. */
    height: 1.5em;
}

.mx_MFileBody_info {
    cursor: pointer;

    .mx_MFileBody_info_icon {
        background-color: $system;
        border-radius: 20px;
        display: inline-block;
        width: 32px;
        height: 32px;
        position: relative;
        vertical-align: middle;
        margin-right: 12px;

        &::before {
            content: "";
            mask-repeat: no-repeat;
            mask-position: center;
            mask-size: cover;
            mask-image: url("$(res)/img/element-icons/room/composer/attach.svg");
            background-color: $secondary-content;
            width: 15px;
            height: 15px;

            position: absolute;
            top: 8px;
            left: 8px;
        }
    }

    .mx_MFileBody_info_filename {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: inline-block;
        width: calc(100% - 32px - 12px); /* 32px icon, 12px margin on the icon */
        vertical-align: middle;
    }
}
