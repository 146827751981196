/*
Copyright 2019-2021 The Matrix.org Foundation C.I.C.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

.mx_ToastContainer {
    position: absolute;
    top: 0;
    left: 70px;
    z-index: 101;
    padding: 4px;
    display: grid;
    grid-template-rows: 1fr 14px 6px;

    &.mx_ToastContainer_stacked::before {
        content: "";
        margin: 0 4px;
        grid-row: 2 / 4;
        grid-column: 1;
        background-color: $system;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.5);
        border-radius: 8px;
    }

    .mx_Toast_toast {
        grid-row: 1 / 3;
        grid-column: 1;
        background-color: var(--cpd-color-bg-canvas-default);
        color: $primary-content;
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
        border: var(--cpd-border-width-1) solid var(--cpd-color-border-interactive-secondary);
        border-radius: 12px;
        overflow: hidden;
        display: grid;
        grid-template-columns: 22px 1fr;
        column-gap: 8px;
        row-gap: 4px;
        padding: var(--cpd-space-3x);

        &.mx_Toast_hasIcon {
            &::before,
            &::after {
                content: "";
                width: 22px;
                height: 22px;
                grid-column: 1;
                grid-row: 1;
                mask-size: 100%;
                mask-position: center;
                mask-repeat: no-repeat;
                background-size: 100%;
                background-repeat: no-repeat;
            }

            &.mx_Toast_icon_verification::after {
                mask-image: url("$(res)/img/e2e/normal.svg");
                background-color: $primary-content;
            }

            &.mx_Toast_icon_verification_warning {
                /* white infill for the hollow svg mask */
                &::before {
                    background-color: #ffffff;
                    mask-image: url("$(res)/img/e2e/normal.svg");
                    mask-size: 80%;
                }

                &::after {
                    mask-image: url("$(res)/img/e2e/warning.svg");
                    background-color: $e2e-warning-color;
                }
            }

            &.mx_Toast_icon_secure_backup::after {
                mask-image: url("$(res)/img/feather-customised/secure-backup.svg");
                background-color: $primary-content;
            }

            &.mx_Toast_icon_labs::after {
                mask-image: url("$(res)/img/element-icons/flask.svg");
                background-color: $secondary-content;
            }

            .mx_Toast_title,
            .mx_Toast_body {
                grid-column: 2;
            }
        }
        &:not(.mx_Toast_hasIcon) {
            padding-left: 12px;

            .mx_Toast_title {
                grid-column: 1 / -1;
            }
        }

        .mx_Toast_title,
        .mx_Toast_description {
            padding-right: 8px;
        }

        .mx_Toast_title {
            display: flex;
            align-items: center;
            column-gap: 8px;
            width: 100%;
            box-sizing: border-box;

            h2 {
                margin: 0;
                font: var(--cpd-font-heading-sm-medium);
                font-weight: var(--cpd-font-weight-medium);
                display: inline;
                width: auto;
            }

            .mx_Toast_title_countIndicator {
                font-size: $font-12px;
                line-height: $font-22px;
                color: $secondary-content;
                margin-inline-start: auto; /* on the end side of the div */
            }
        }

        .mx_Toast_body {
            grid-column: 1 / 3;
            grid-row: 2;
        }

        .mx_Toast_buttons {
            display: flex;
            justify-content: flex-end;
            column-gap: 5px;

            .mx_AccessibleButton {
                min-width: 96px;
                box-sizing: border-box;
            }
        }

        .mx_Toast_description {
            max-width: 272px;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 4px 0 11px 0;
            font: var(--cpd-font-body-sm-regular);

            a {
                text-decoration: none;
            }
        }

        .mx_Toast_detail {
            color: $secondary-content;
        }

        .mx_Toast_deviceID {
            font-size: $font-10px;
        }
    }
}
