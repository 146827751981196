/*
Copyright 2020 The Matrix.org Foundation C.I.C.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

.mx_NotificationBadge {
    &:not(.mx_NotificationBadge_visible) {
        display: none;
    }

    /* Badges are structured a bit weirdly to work around issues with non-monospace */
    /* font styles. The badge pill is actually a background div and the count floats */
    /* within that. For example: */
    /*  ( 99+ ) <-- Rounded pill is a _bg class. */
    /*     ^- The count is an element floating within that. */

    &.mx_NotificationBadge_visible {
        background-color: $roomtile-default-badge-bg-color;

        /* Create a flexbox to order the count a bit easier */
        display: flex;
        align-items: center;
        justify-content: center;

        /* These are the 3 background types */

        &.mx_NotificationBadge_dot {
            width: 8px;
            height: 8px;
            border-radius: 8px;
            background-color: var(--cpd-color-icon-primary);

            .mx_NotificationBadge_count {
                display: none;
            }

            /* Redundant sounding name, but a notification badge that indicates there is a regular,
             * non-highlight notification
             * The green colour only applies for notification dot: badges indicating the same notification
             * level are the standard grey.
             */
            &.mx_NotificationBadge_level_notification {
                background-color: var(--cpd-color-icon-success-primary);
            }
        }

        /* Badges for highlight notifications. Style for notification level
         * badges is in _EventTile.scss because it applies only to notification
         * dots, not badges.
         */
        &.mx_NotificationBadge_level_highlight {
            background-color: var(--cpd-color-icon-critical-primary);
        }

        &.mx_NotificationBadge_knocked {
            mask-image: url("$(res)/img/element-icons/ask-to-join.svg");
            width: 12px;
            height: 16px;
        }

        &.mx_NotificationBadge_2char {
            width: $font-16px;
            height: $font-16px;
            border-radius: $font-16px;
        }

        &.mx_NotificationBadge_3char {
            width: $font-26px;
            height: $font-16px;
            border-radius: $font-16px;
        }

        /* The following is the floating badge */

        .mx_NotificationBadge_count {
            font-size: $font-10px;
            line-height: $font-14px;
            font-weight: var(--cpd-font-weight-semibold);
            color: var(--cpd-color-text-on-solid-primary);
        }
    }
}
