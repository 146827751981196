/*
Copyright 2023 The Matrix.org Foundation C.I.C.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

.mx_RoomHeader {
    height: 64px;
    box-sizing: border-box;
    padding: 0 var(--cpd-space-3x);
    border-bottom: 1px solid $separator;
    background-color: $background;
    transition: all 0.2s ease;
}

.mx_RoomHeader:hover {
    background-color: $header-panel-bg-hover;
}

.mx_RoomHeader_infoWrapper {
    /* unset button styles */
    background: unset;
    border: unset;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    gap: var(--cpd-space-3x);
    text-align: left;
}

.mx_RoomHeader_info {
    /* We want the spacing to be 64px, 13x = 52px but we have a flex gap of
       12px set on the flex container, which sums up to 64 */
    padding-right: var(--cpd-space-13x);
}

.mx_RoomHeader_truncated {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;

    overflow: hidden;
    word-break: break-all;
    text-overflow: ellipsis;
}

.mx_RoomHeader_heading {
    display: flex;
    gap: var(--cpd-space-1x);
    align-items: center;
}

.mx_RoomHeader_topic {
    height: 0;
    opacity: 0;
    transition: all var(--transition-standard) ease 0.1s;
    /* Emojis are rendered a bit bigger than text in the timeline
        Make them compact/the same size as text here */
    .mx_Emoji {
        font-size: inherit;
    }
}

.mx_RoomHeader:hover,
.mx_RoomHeader:focus-within {
    .mx_RoomHeader_topic {
        /* height needed to compute the transition, it equals to the `line-height`
        value in pixels */
        height: calc($font-13px * 1.5);
        opacity: 1;

        a:hover {
            text-decoration: underline;
        }
    }
}

.mx_RoomHeader_icon {
    flex-shrink: 0;
}

.mx_RoomHeader .mx_FacePile {
    color: $secondary-content;
    background: $background;
    display: flex;
    align-items: center;
    gap: var(--cpd-space-2x);
    border-radius: 9999px;
    padding: var(--cpd-space-1-5x);
    cursor: pointer;
    user-select: none;

    /* RoomAvatar doesn't pass classes down to avatar
    So set style here
    using div because compound classes are not stable */
    > div {
        flex-shrink: 0;
    }

    &:hover {
        color: $primary-content;
        background: var(--cpd-color-bg-subtle-primary);
    }
}

.mx_RoomHeader .mx_BaseAvatar {
    flex-shrink: 0;
}
