/*
Copyright 2022 The Matrix.org Foundation C.I.C.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

/* SCSS variables representing a range of standard lengths.
 *
 * Avoid using these in new code: we cannot adjust their values without causing massive confusion, so they are
 * effectively equivalent to using hardcoded values.
 *
 * In future, we plan to introduce variables named according to their purpose rather than their size. Additionally,
 * we want switch to custom CSS properties (https://github.com/vector-im/element-web/issues/21656), so we might have
 * `--spacing-standard` or something. For now, you might as well use hardcoded px values for lengths (except for font
 * sizes, for which see the `$font-<N>px` variables).
 */
$spacing-2: 2px;
$spacing-4: 4px;
$spacing-8: 8px;
$spacing-12: 12px;
$spacing-16: 16px;
$spacing-20: 20px;
$spacing-24: 24px;
$spacing-28: 28px;
$spacing-32: 32px;
$spacing-40: 40px;
$spacing-48: 48px;
$spacing-64: 64px;
