.sfGradientBorder {
  width: 468px;
  height: 317px;
  background: linear-gradient(to bottom, #a09efd, #73158d);
  border-radius: 16px;
}

.sfHideExtent {
  overflow: hidden;
  position: relative;
}

.sfInnerOfBorder {
  width: 463px;
  height: 312px;
  background: url("https://app.element.io/themes/element/img/backgrounds/lake.jpg")
    center center / cover fixed;
  border-radius: 15px;
}

.sfPositionRelative {
  position: relative;
}

.sfCenterVerticallyHorizon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sfCenterVerticallyHorizonStackItemsVertically {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.sfRedHat {
  font-family: "RedHat", sans-serif;
}

.sfNunito {
  font-family: "Nunito", sans-serif !important;
}

.sfPositionAbs {
  position: absolute;
}

.sfLockETHBtn {
  width: 404px;
  height: 54px;
  border-radius: 8px;
  text-align: center;
  background: linear-gradient(to bottom, #7c1596, #6b68ff);
}

.sfTextAlignCenter {
  text-align: center;
}

.sfLockEthHeader {
  width: 404px;
  height: 86px;
}

.sfHeaderLockEth {
  font-weight: 700;
  font-size: 40px;
  line-height: 56px;
}

.sfTextWhite {
  color: #ffffff !important;
}

.sfBackgroundRed {
  background: red;
}

.sfBackgroundYellow {
  background: yellow;
}

.sfBackgroundBlue {
  background: blue;
}

.sfChildHeader {
  font-weight: 500;
  font-size: 16px;
  line-height: 22.4px;
  opacity: 0.6;
}

.sfLockETHBtn:hover {
  cursor: pointer;
}

.sfZIndex6 {
  z-index: 6;
}

.sfZIndex5 {
  z-index: 5;
}

.sfLockETHText {
  font-size: 20px;
  font-weight: 400;
  color: #ffffff !important;
  z-index: 10;
}

.sfRectangleOpacity50 {
  width: 340px;
  height: 46px;
  background: #000000;
  opacity: 0.5;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.sfFlex {
  display: flex;
}

.sfArrowButton {
  background: #ffffff;
  width: 16px;
  height: 16px;
  border-radius: 16px;
  padding: 4px;
}

.sfSpaceAround {
  display: flex;
  justify-content: space-around;
}

.sfSpaceBetween {
  display: flex;
  justify-content: space-between;
}

.sfMarginLeftMinus9 {
  margin-left: -9px;
}

.sfMarginLeftMinus4 {
  margin-left: -4px;
}

.sfMarginRightMinus10 {
  margin-right: -10px;
}

.sfHoverRightArrow:hover {
  animation: jump 0.5s ease;
}

@keyframes jump {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-2px);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(-1);
  }
  100% {
    transform: translateY(0);
  }
}

.sfAmountOfEthInput {
  width: 404px;
  height: 48px;
  border: 1px solid #373b46;
  border-radius: 8px;
  background-color: #222325;
}

.sfInputFieldAmountOfETH {
  color: #ffffff !important;
  border: none;
  outline: none;
}

.sfMarginBottom28 {
  margin-bottom: 28px;
}

.sfMarginBottom12 {
  margin-bottom: 12px;
}

.sfInputWidthHeight {
  width: 340px;
  height: 22px;
}

.sfFontWeight400 {
  font-weight: 400;
}

.sfSizeOfText16 {
  font-size: 16px;
}

