/*
Copyright 2015, 2016 OpenMarket Ltd
Copyright 2019 - 2023 The Matrix.org Foundation C.I.C.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

.mx_AppPermission {
    font-size: $font-12px;
    width: 100%; /* make mx_AppPermission fill width of mx_AppTileBody so that scroll bar appears on the edge */
    overflow-y: scroll;
    .mx_AppPermission_bolder {
        font-weight: var(--cpd-font-weight-semibold);
    }
    .mx_AppPermission_content {
        margin-block: auto; /* place at the center */

        > div {
            margin-block: 12px;
        }

        .mx_AppPermission_content_bolder {
            font-weight: var(--font-semi-bold);
        }

        .mx_TextWithTooltip_target--helpIcon {
            display: inline-block;
            height: $font-14px; /* align with characters on the same line */
            vertical-align: middle;

            .mx_Icon {
                color: $accent;
            }
        }
    }
}
