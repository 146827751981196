/* the 'src' links are relative to the bundle.css, which is in a subdirectory.
 */

/* Inter unexpectedly contains various codepoints which collide with emoji, even
   when variation-16 is applied to request the emoji variant.  From eyeballing
   the emoji picker, these are: 20e3, 23cf, 24c2, 25a0-25c1, 2665, 2764, 2b06, 2b1c.
   Therefore we define a unicode-range to load which excludes the glyphs
   (to avoid having to maintain a fork of Inter). */

$inter-unicode-range: U+0000-20e2, U+20e4-23ce, U+23d0-24c1, U+24c3-259f,
  U+25c2-2664, U+2666-2763, U+2765-2b05, U+2b07-2b1b, U+2b1d-10FFFF;

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  unicode-range: $inter-unicode-range;
  src:
    url("$(res)/fonts/Inter/Inter-Regular.woff2?v=3.18") format("woff2"),
    url("$(res)/fonts/Inter/Inter-Regular.woff?v=3.18") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  unicode-range: $inter-unicode-range;
  src:
    url("$(res)/fonts/Inter/Inter-Italic.woff2?v=3.18") format("woff2"),
    url("$(res)/fonts/Inter/Inter-Italic.woff?v=3.18") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  unicode-range: $inter-unicode-range;
  src:
    url("$(res)/fonts/Inter/Inter-Medium.woff2?v=3.18") format("woff2"),
    url("$(res)/fonts/Inter/Inter-Medium.woff?v=3.18") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  unicode-range: $inter-unicode-range;
  src:
    url("$(res)/fonts/Inter/Inter-MediumItalic.woff2?v=3.18") format("woff2"),
    url("$(res)/fonts/Inter/Inter-MediumItalic.woff?v=3.18") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  unicode-range: $inter-unicode-range;
  src:
    url("$(res)/fonts/Inter/Inter-SemiBold.woff2?v=3.18") format("woff2"),
    url("$(res)/fonts/Inter/Inter-SemiBold.woff?v=3.18") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  unicode-range: $inter-unicode-range;
  src:
    url("$(res)/fonts/Inter/Inter-SemiBoldItalic.woff2?v=3.18") format("woff2"),
    url("$(res)/fonts/Inter/Inter-SemiBoldItalic.woff?v=3.18") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  unicode-range: $inter-unicode-range;
  src:
    url("$(res)/fonts/Inter/Inter-Bold.woff2?v=3.18") format("woff2"),
    url("$(res)/fonts/Inter/Inter-Bold.woff?v=3.18") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  unicode-range: $inter-unicode-range;
  src:
    url("$(res)/fonts/Inter/Inter-BoldItalic.woff2?v=3.18") format("woff2"),
    url("$(res)/fonts/Inter/Inter-BoldItalic.woff?v=3.18") format("woff");
}

/* latin-ext */
@font-face {
  font-family: "Inconsolata";
  font-style: normal;
  font-weight: 400;
  src:
    local("Inconsolata Regular"),
    local("Inconsolata-Regular"),
    url("$(res)/fonts/Inconsolata/QldKNThLqRwH-OJ1UHjlKGlX5qhExfHwNJU.woff2")
      format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Inconsolata";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    local("Inconsolata Regular"),
    local("Inconsolata-Regular"),
    url("$(res)/fonts/Inconsolata/QldKNThLqRwH-OJ1UHjlKGlZ5qhExfHw.woff2")
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Inconsolata";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src:
    local("Inconsolata Bold"),
    local("Inconsolata-Bold"),
    url("$(res)/fonts/Inconsolata/QldXNThLqRwH-OJ1UHjlKGHiw71n5_zaDpwm80E.woff2")
      format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Inconsolata";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src:
    local("Inconsolata Bold"),
    local("Inconsolata-Bold"),
    url("$(res)/fonts/Inconsolata/QldXNThLqRwH-OJ1UHjlKGHiw71p5_zaDpwm.woff2")
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "RedHat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("$(res)/fonts/RedHatDisplayRegular.ttf") format("truetype");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD, U+0000-00FF, U+0100-017F, U+0180-024F, U+0300-036F,
    U+1EA0-1EFF;
}


@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("$(res)/fonts/Nunito/Nunito-Regular.ttf") format("truetype");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD, U+0000-00FF, U+0100-017F, U+0180-024F, U+0300-036F,
    U+1EA0-1EFF;
}
