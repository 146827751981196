/*
Copyright 2021 The Matrix.org Foundation C.I.C.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

/* A "media body" is any file upload looking thing, apart from images and videos (they */
/* have unique styles). */

.mx_MediaBody {
    background-color: $panels;
    border-radius: 12px;
    max-width: 243px; /* use max-width instead of width so it fits within right panels */

    color: $secondary-content;
    font: var(--cpd-font-body-md-regular);
    line-height: $font-24px;

    padding: 6px 12px;
}
