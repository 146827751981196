/*
Copyright 2015, 2016 OpenMarket Ltd
Copyright 2020 - 2021 The Matrix.org Foundation C.I.C.
Copyright 2021 - 2022 Šimon Brandner <simon.bra.ag@gmail.com>

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

/* data-whatintent makes more sense here semantically but then the tooltip would stay visible without the button */
/* which looks broken, so we match the behaviour of tooltips which is fine too. */
[data-whatinput="mouse"] .mx_LegacyCallViewButtons.mx_LegacyCallViewButtons_hidden {
    opacity: 0.001; /* opacity 0 can cause a re-layout */
    pointer-events: none;
}

.mx_LegacyCallViewButtons {
    --LegacyCallViewButtons_dropdownButton-size: 16px;

    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 32px;
    opacity: 1;
    transition: opacity 0.5s;
    z-index: 200; /* To be above _all_ feeds */
    gap: 18px;

    .mx_LegacyCallViewButtons_button {
        cursor: pointer;

        background-color: $call-view-button-on-background;

        border-radius: 100%;
        height: 40px;
        width: 40px;

        display: flex;
        justify-content: center;
        align-items: center;

        position: relative;

        box-shadow: 0px 4px 4px 0px #00000026; /* Same on both themes */

        &::before {
            content: "";
            display: inline-block;

            mask-repeat: no-repeat;
            mask-size: contain;
            mask-position: center;

            background-color: $call-view-button-on-foreground;

            height: 24px;
            width: 24px;
        }

        &.mx_LegacyCallViewButtons_dropdownButton {
            width: var(--LegacyCallViewButtons_dropdownButton-size);
            height: var(--LegacyCallViewButtons_dropdownButton-size);

            position: absolute;
            right: 0;
            bottom: 0;

            &::before {
                width: 14px;
                height: 14px;
                mask-image: url("$(res)/img/element-icons/message/chevron-up.svg");
            }

            &.mx_LegacyCallViewButtons_dropdownButton_collapsed::before {
                transform: rotate(180deg);
            }
        }

        /* State buttons */
        &.mx_LegacyCallViewButtons_button_on {
            background-color: $call-view-button-on-background;

            &::before {
                background-color: $call-view-button-on-foreground;
            }

            &.mx_LegacyCallViewButtons_button_mic::before {
                height: 20px;
                mask-image: url("$(res)/img/element-icons/mic.svg");
                width: 20px;
            }

            &.mx_LegacyCallViewButtons_button_vid::before {
                mask-image: url("$(res)/img/voip/call-view/cam-on.svg");
            }

            &.mx_LegacyCallViewButtons_button_screensharing {
                background-color: $accent;

                &::before {
                    mask-image: url("$(res)/img/voip/call-view/screensharing.svg");
                    background-color: white; /* Same on both themes */
                }
            }

            &.mx_LegacyCallViewButtons_button_sidebar::before {
                mask-image: url("$(res)/img/voip/call-view/sidebar-on.svg");
            }
        }

        &.mx_LegacyCallViewButtons_button_off {
            background-color: $call-view-button-off-background;

            &::before {
                background-color: $call-view-button-off-foreground;
            }

            &.mx_LegacyCallViewButtons_button_mic::before {
                height: 20px;
                mask-image: url("$(res)/img/element-icons/Mic-off.svg");
                width: 20px;
            }

            &.mx_LegacyCallViewButtons_button_vid::before {
                mask-image: url("$(res)/img/voip/call-view/cam-off.svg");
            }

            &.mx_LegacyCallViewButtons_button_screensharing {
                background-color: $call-view-button-on-background;

                &::before {
                    mask-image: url("$(res)/img/voip/call-view/screensharing.svg");
                    background-color: $call-view-button-on-foreground;
                }
            }

            &.mx_LegacyCallViewButtons_button_sidebar {
                background-color: $call-view-button-on-background;

                &::before {
                    mask-image: url("$(res)/img/voip/call-view/sidebar-off.svg");
                    background-color: $call-view-button-on-foreground;
                }
            }
        }
        /* State buttons */

        /* Stateless buttons */
        &.mx_LegacyCallViewButtons_dialpad::before {
            mask-image: url("$(res)/img/voip/call-view/dialpad.svg");
        }

        &.mx_LegacyCallViewButtons_button_hangup {
            background-color: $alert;

            &::before {
                mask-image: url("$(res)/img/element-icons/call/hangup.svg");
                background-color: white; /* Same on both themes */
            }
        }

        &.mx_LegacyCallViewButtons_button_more::before {
            mask-image: url("$(res)/img/voip/call-view/more.svg");
        }
        /* Stateless buttons */

        /* Invisible state */
        &.mx_LegacyCallViewButtons_button_invisible {
            visibility: hidden;
            pointer-events: none;
            position: absolute;
        }
        /* Invisible state */
    }
}
