/*
Copyright 2020 The Matrix.org Foundation C.I.C.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

.mx_RoomBreadcrumbs {
    width: 100%;

    /* Create a flexbox for the crumbs */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 12px;

    .mx_RoomBreadcrumbs_crumb {
        margin-right: 8px;
        width: 32px;
    }

    /* These classes come from the CSSTransition component. There's many more classes we */
    /* could care about, but this is all we worried about for now. The animation works by */
    /* first triggering the enter state with the newest breadcrumb off screen (-40px) then */
    /* sliding it into view. */
    &.mx_RoomBreadcrumbs-enter {
        transform: translateX(-40px); /* 32px for the avatar, 8px for the margin */
    }
    &.mx_RoomBreadcrumbs-enter-active {
        transform: translateX(0);

        /* Timing function is as-requested by design. */
        /* NOTE: The transition time MUST match the value passed to CSSTransition! */
        transition: transform 640ms cubic-bezier(0.66, 0.02, 0.36, 1);
    }

    .mx_RoomBreadcrumbs_placeholder {
        font: var(--cpd-font-body-md-semibold);
        line-height: 32px; /* specifically to match the height this is not scaled */
        height: 32px;
    }
}
