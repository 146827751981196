.mx_safeum-logging-container {
 display: flex;
 justify-content: center;
 position: relative;
 background-color: black;
 width: 100%;
 height: 100%;
}

.mx_elipse7-img {
 position: absolute;
 top: 14%;
 left: 0;
}

.mx_elipse8-img {
 position: absolute;
 right: 0;
}

.mx_login-box {
 position: absolute;
 top: 50%;
 left: 50%;
 width: 468px;
 height: 244px;
 display: flex;
 transform: translate(-50%, -50%);
 flex-direction: column;
 justify-content: center;
 align-items: center;
 padding: 32px;
 border-radius: 16px;
 color: #ffffff;
 font-family: Arial, sans-serif;
 text-align: center;
 overflow: hidden;
}

.mx_gradient-border {
 content: "";
 height: 244px;
 width: 468px;
 top: 0;
 left: 0;
 right: 0;
 bottom: 0;
 border-radius: 16px;
 padding: 2.5px;
 background: linear-gradient(180deg, #5f5cff 0%, #75158d 100%);
 -webkit-mask:
   linear-gradient(#fff 0 0) content-box,
   linear-gradient(#fff 0 0);
 -webkit-mask-composite: xor;
 mask-composite: exclude;
 z-index: 0;
}

.mx_dot-box {
 position: absolute;
 z-index: 100;
}

.mx_text-container {
 position: absolute;
 z-index: 100;
 width: 100%;
 height: 100%;
 padding: 32px;
 box-sizing: border-box;
}

.mx_text-content {
 display: flex;
 align-items: center;
 flex-flow: column;
}

.mx_login-title {
 margin-top: 15px;
 font-size: 40px;
 font-weight: 700;
 line-height: 56px;
 text-align: center;
}

.mx_login-subtitle,
.mx_login-loading {
 color: #ffffff;
 font-size: 16px;
 font-weight: 500;
 line-height: 22.4px;
 text-align: center;
}

.mx_login-loading {
 margin-top: 25px;
}

.mx_login-subtitle {
 margin-top: 15px;
}

.mx_login-blast {
 width: 190px;
 height: 44px;
 position: absolute;
 bottom: 20px;
 z-index: 100;
 left: 50%;
 transform: translate(-50%, -50%);
}



