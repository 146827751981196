/*
Copyright 2019 New Vector Ltd
Copyright 2022 Šimon Brandner <simon.bra.ag@gmail.com>

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

.mx_CopyableText {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    width: max-content;
    max-width: 100%;

    &.mx_CopyableText_border {
        overflow: auto;
        border-radius: 5px;
        border: solid 1px $light-fg-color;
        margin-bottom: 10px;
        margin-top: 10px;
        padding: 10px 0 10px 10px;
    }

    .mx_CopyableText_copyButton {
        flex-shrink: 0;
        /* using em here to adapt to the local font size */
        width: 1em;
        height: 1em;
        cursor: pointer;
        padding-left: 12px;
        padding-right: 10px;
        display: block;
        /* If the copy button is used within a scrollable div, make it stick to the right while scrolling */
        position: sticky;
        right: 0;
        /* center to first line */
        top: 0.15em;
        background-color: $background;

        &::before {
            content: "";
            mask-image: url($copy-button-url);
            mask-position: center center;
            mask-repeat: no-repeat;
            mask-size: contain;
            background-color: $message-action-bar-fg-color;
            width: 1em;
            height: 1em;
            display: block;
            background-repeat: no-repeat;
        }
    }
}
