/*
Copyright 2018 New Vector Ltd

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

/* make any scrollbar grey and thin */
html {
    scrollbar-color: $scrollbar-thumb-color transparent;
}
/* scrollbar-width is not inherited (but -color is, why?!), */
/* so declare it on every element */
* {
    scrollbar-width: thin;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: $scrollbar-thumb-color;
}

/* make auto-hide scrollbars not transparent again on hover */
.mx_AutoHideScrollbar:hover {
    scrollbar-color: $scrollbar-thumb-color transparent;

    &::-webkit-scrollbar-thumb {
        background-color: $scrollbar-thumb-color;
    }
}

/* make scrollbars transparent for autohide scrollbars */
.mx_AutoHideScrollbar {
    overflow-x: hidden;
    overflow-y: auto;
    overflow-y: overlay; /* where supported */
    -ms-overflow-style: -ms-autohiding-scrollbar;

    &::-webkit-scrollbar {
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: transparent;
    }

    scrollbar-color: transparent transparent;
}
