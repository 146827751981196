/*
Copyright 2020 The Matrix.org Foundation C.I.C.

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

/* Note: this component expects to be contained within a flexbox */
.mx_RoomSearch {
    flex: 1;
    min-width: 0;
    border-radius: 8px;
    background-color: $panel-actions;
    /* keep border thickness consistent to prevent movement */
    border: 1px solid transparent;
    height: 28px;
    padding: 1px;

    /* Create a flexbox for the icons (easier to manage) */
    display: flex;
    align-items: center;

    cursor: pointer;

    .mx_RoomSearch_icon {
        width: 16px;
        height: 16px;
        mask: url("$(res)/img/element-icons/roomlist/search.svg");
        mask-repeat: no-repeat;
        background-color: $secondary-content;
        margin-left: 7px;
        margin-bottom: 2px;
        flex-shrink: 0;
    }

    .mx_RoomSearch_spotlightTriggerText {
        color: $tertiary-content;
        flex: 1;
        min-width: 0;
        /* the following rules are to match that of a real input field */
        overflow: hidden;
        margin: 9px;
        font: var(--cpd-font-body-sm-semibold);
    }

    .mx_RoomSearch_shortcutPrompt {
        border-radius: 6px;
        background-color: $panel-actions;
        padding: 2px 4px;
        user-select: none;
        font-size: $font-12px;
        line-height: $font-15px;
        font-family: inherit;
        font-weight: var(--cpd-font-weight-semibold);
        color: $light-fg-color;
        margin-right: 6px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &.mx_RoomSearch_minimized {
        height: 32px;
        min-height: 32px;
        width: 32px;
        box-sizing: border-box;

        .mx_RoomSearch_icon {
            margin: 0 auto;
            padding: 1px;
            align-self: center;
        }

        .mx_RoomSearch_shortcutPrompt {
            display: none;
        }
    }

    &:hover {
        background-color: $tertiary-content;

        .mx_RoomSearch_spotlightTriggerText {
            color: $background;
        }

        .mx_RoomSearch_shortcutPrompt {
            background-color: $background;
            color: $secondary-content;
        }

        .mx_RoomSearch_icon {
            background-color: $background;
        }
    }
}
